import { IBaseAudit } from '../auditable.interface';
import { IAddress } from '../location/address.interface';
import { IPaymentOption } from '../payment-option.interface';
import { IDeliveryType } from '../delivery-type.interface';
import { ISuperMarket } from '../supermarket/super-market.interface';
import { ITimeSlot } from '../supermarket/time-slot.interface';
import { IOrderProduct } from './order-product.interface';
import { IOrder } from './order.interface';
import { ICommonOption, ISort } from '../common.interface';
import { IDeliveryOption } from '../delivery-option.interface';
import { IDeliveryZoneDistrict } from '../fulfillment/delivery-zone.interface';
import { IDispatchJob, IDispatchJobHold } from '../fulfillment/dispatch-job.interface';
import { IOrderPickingShipmentBins, IOrderPickingShipmentProducts } from './order-picking-stagger.interface';
import { IEmployeeData } from '../employee.interface';
import { IOrderAddress } from './order-address.interface';
import { IOrderPickingBatch, IOrderPickingShipment } from '../fulfillment/order-picking.interface';
import { UserTypeEnum } from '../user.interface';

export interface IOrderShipment extends IBaseAudit {
  order_id: number;
  supermarket_id: number;
  delivery_type_id: number;
  delivery_date: Date;
  total: number;
  tax_total: number;
  promotion_woa_percentage?: number;
  promotion_woa_max_discount?: number;
  discount_given: number;
  status: OrderShipmentStatusEnum;
  processing_start_at: Date;
  driver_id?: number;
  delivered_at?: Date;
  delivery_charges?: number;
  time_slot_id?: number;
  dispatch_job_id?: number;
  is_delivery_on_hold: boolean;
  is_resetted?: boolean;
  order?: IOrder;
  order_products?: IOrderProduct[];
  supermarket?: ISuperMarket;
  delivery_type?: IDeliveryType;
  dispatch_job?: IDispatchJob;
  order_picking_shipment?: IOrderPickingShipment[];
  order_picking_shipment_bins?: IOrderPickingShipmentBins[];
  order_picking_shipment_products?: IOrderPickingShipmentProducts[];
  hold_reasons?: IDispatchJobHold[];
  delivery_order?: number;
  formatted_time_slot?: string;
  shipment_reschedule_history?: IOrderShipmentRescheduleHistory[];
  driver?: IEmployeeData;
  delivery_on_time?: boolean;
  is_rescheduled?: boolean;
  invoice_pdf_url?: string;
}

export interface IOrderShipmentRescheduleHistory extends IBaseAudit {
  order_shipment_id: number;
  user_id: number;
  to_date: Date;
  to_time_slot: string;
  to_time_slot_id: number;
  from_date: Date;
  from_time_slot: string;
  from_time_slot_id: number;
  comment: string;
}

export interface IShipmentFulfillmentHistory extends IBaseAudit {
  order_shipment_id: number;
  reason?: string;
  state: ShipmentFulfillmentStateEnum;
  created_by_user_name?: string;
  created_by_user_type?: UserTypeEnum;
  updated_by?: number;
}

export interface IDeliveryBatchOrderShipment extends IOrderShipment {
  delivery_district?: IDeliveryZoneDistrict;
  number_of_bins?: number;
  weight?: number;
  shipping_address?: IOrderAddress;
}

export interface IDeliveryBatchShipmentsListPayload {
  supermarket_id: number;
  delivery_date: string;
  delivery_type_id?: number;
  time_slot_id?: number;
}

export interface IShipmentFulfillmentDetails {
  order_picking_bins?: IOrderPickingShipmentBins[];
  order_picking_products?: IOrderPickingShipmentProducts[];
  order_picking_shipment?: IOrderPickingShipment;
  order_picking_batch?: IOrderPickingBatch;
  delivery_batch?: IDispatchJob;
  binCounts?: number;
  oversizedProducts?: number;
}

export type IShipmentFulfillmentHistoryListData = {
  page: number;
};

export interface IShipmentFulfillmentHistoryPayload extends IShipmentFulfillmentHistoryListData {
  [index: string]: string | number;
}

export interface IGrpcGetOrderPickingShipmentReq {
  time_slot_ids?: number[];
  supermarket_ids: number[];
}

export interface IGrpcGetOrderPickingShipmentRes {
  time_slots: ITimeSlot[];
  supermarkets: ISuperMarket[];
  delivery_types: IDeliveryType[];
  delivery_options: IDeliveryOption[];
}

export interface IGrpcGetOrderShipments {
  supermarket_ids: number[];
  time_slot_ids: number[];
  start_date: string;
  end_date: string;
}

export interface IGrpcGetOrderShipmentsReq {
  order_shipments: IGrpcGetOrderShipments;
}

export interface IGrpcGetOrderShipmentsRes {
  order_shipments: IOrderShipment[];
}

export interface IShipmentSupermarket extends ICommonOption<number> {
  status?: OrderShipmentStatusEnum;
  disabled?: boolean;
}

export interface IGrpcGetPaymentAndAddressReq {
  payment_option_ids?: number[];
  address_ids?: number[];
}

export interface IGrpcGetPaymentAndAddressRes {
  payment_options: IPaymentOption[];
  address: IAddress[];
}

export interface IGrpcGetSupermarketDetailsDriverAndPaymentReq {
  driver_id: number;
  supermarket_id: number;
  payment_option_ids: number[];
}

export interface IGrpcGetSupermarketDetailsDriverAndPaymentRes {
  supermarket_location: ISuperMarket;
  payment_options: IPaymentOption[];
  driver: IEmployeeData;
}

export interface IGrpcGetPaymentOptionsRes {
  payment_options: IPaymentOption[];
}

export interface IActiveAndCompletedShipments {
  activeShipmentIds: number[];
  deliveredShipments: IOrderShipment[];
}

export interface IOrderShipmentRedisData {
  list: IOrderShipment[];
  totalCount: number;
}

export interface IOrderShipmentListData extends ISort {
  page: number;
  id: string;
  order_id: number;
  delivery_date: string;
  delivery_type_id: number;
  supermarket_id: number;
  status: string;
  total: number;
  delivery_charges: number;
  time_slot: string;
  processing_start_at: string;
}

export interface IOrderShipmentListPayload extends IOrderShipmentListData {
  [index: string]: string | number;
}

export enum OrderShipmentGridFilterEnum {
  ID = 'id',
  ORDER_ID = 'order_id',
  DELIVERY_DATE = 'delivery_date',
  DELIVERY_TYPE_ID = 'delivery_type_id',
  STATUS = 'status',
  SUPERMARKET_ID = 'supermarket_id',
  PROCESSING_START_AT = 'processing_start_at',
  TIME_SLOT = 'time_slot',
  TOTAL = 'total',
  DELIVERY_CHARGES = 'delivery_charges',
  SORT_BY = 'sortBy',
  SORT = 'sort'
}

export enum OrderShipmentStatusEnum {
  PENDING = 'pending',
  IN_PROGRESS = 'in-progress',
  PICKING_IN_PROGRESS = 'picking-in-progress',
  PICKING_HOLD = 'picking-hold',
  ISSUE = 'issue',
  PICKED = 'picked',
  ASSIGNED_TO_PACKER = 'assigned-to-packer',
  PACKED = 'packed',
  ASSIGNED_TO_DELIVERY_BATCH = 'assigned-to-delivery-batch',
  DISPATCH_HOLD = 'dispatch-hold',
  OUT_FOR_DELIVERY = 'out-for-delivery',
  ARRIVED = 'arrived',
  READY_TO_PICKUP = 'ready-to-pickup',
  DELIVERED = 'delivered',
  CANCELLED = 'cancelled'
}

export const orderShipmentStatusOptions: ICommonOption<OrderShipmentStatusEnum>[] = [
  {
    id: OrderShipmentStatusEnum.PENDING,
    name: 'Pending'
  },
  {
    id: OrderShipmentStatusEnum.IN_PROGRESS,
    name: 'In Progress'
  },
  {
    id: OrderShipmentStatusEnum.ISSUE,
    name: 'Issue'
  },
  {
    id: OrderShipmentStatusEnum.PICKED,
    name: 'Picked'
  },
  {
    id: OrderShipmentStatusEnum.ASSIGNED_TO_PACKER,
    name: 'Assigned To Packer'
  },
  {
    id: OrderShipmentStatusEnum.PACKED,
    name: 'Packed'
  },
  {
    id: OrderShipmentStatusEnum.ASSIGNED_TO_DELIVERY_BATCH,
    name: 'Assigned To Delivery Batch'
  },
  {
    id: OrderShipmentStatusEnum.OUT_FOR_DELIVERY,
    name: 'Out For Delivery'
  },
  {
    id: OrderShipmentStatusEnum.ARRIVED,
    name: 'Arrived'
  },
  {
    id: OrderShipmentStatusEnum.READY_TO_PICKUP,
    name: 'Ready To Pickup'
  },
  {
    id: OrderShipmentStatusEnum.DELIVERED,
    name: 'Delivered'
  },
  {
    id: OrderShipmentStatusEnum.DISPATCH_HOLD,
    name: 'Dispatch Hold'
  },
  {
    id: OrderShipmentStatusEnum.PICKING_IN_PROGRESS,
    name: 'Picking In Progress'
  },
  {
    id: OrderShipmentStatusEnum.PICKING_HOLD,
    name: 'Picking Hold'
  },
  {
    id: OrderShipmentStatusEnum.CANCELLED,
    name: 'Cancelled'
  }
];

export enum ShipmentFulfillmentStateEnum {
  OUT_FOR_DELIVERY = 'out-for-delivery',
  ISSUE = 'issue',
  PACKED = 'packed',
  ON_HOLD = 'on-hold',
  ARRIVED = 'arrived',
  PACKING_IN_PROGRESS = 'packing-in-progress',
  ASSIGNED_TO_DELIVERY_BATCH = 'assigned-to-delivery-batch',
  DELIVERED_PARTIALLY_RETURNED = 'delivered-partially-returned',
  PICKING_IN_PROGRESS = 'picking-in-progress',
  PICKED = 'picked',
  ASSIGNED_TO_PACKER = 'assigned-to-packer',
  PARTIALLY_DELIVERED = 'partially-delivered',
  PENDING = 'pending',
  DELIVERED = 'delivered',
  CANCELLED = 'cancelled'
}

export const orderShipmentSelect = {
  id: true,
  supermarket_id: true,
  delivery_type_id: true,
  time_slot_id: true,
  delivery_date: true
};

export const orderShipmentsSelect = {
  id: true,
  order_id: true,
  supermarket_id: true,
  delivery_type_id: true,
  delivery_date: true,
  formatted_time_slot: true,
  processing_start_at: true,
  total: true,
  delivery_charges: true,
  status: true,
  driver_id: true,
  delivered_at: true,
  order: {
    id: true,
    status: true,
    delivery_option_id: true
  }
};

export const orderShipmentDetailSelect = {
  id: true,
  order_id: true,
  supermarket_id: true,
  status: true,
  total: true,
  created_at: true,
  delivery_date: true,
  formatted_time_slot: true,
  delivered_at: true,
  order: {
    id: true,
    delivery_option_id: true,
    payment_option_id: true,
    city_id: true,
    currency: true,
    status: true,
    order_addresses: {
      type: true,
      latitude: true,
      longitude: true,
      country: true,
      district: true,
      city: true,
      area: true,
      street_no: true,
      postal_code: true,
      formatted_address: true,
      is_verified: true,
      delivery_instruction: true
    }
  }
};

export const customerActiveShipmentsSelect = {
  id: true,
  order_id: true,
  supermarket_id: true,
  formatted_time_slot: true,
  status: true,
  delivery_date: true,
  total: true,
  tax_total: true,
  delivery_charges: true,
  order_products: {
    id: true,
    category_id: true,
    product_id: true,
    product_variant_id: true,
    quantity: true,
    replacement_of: true,
    replaced_by: true,
    picked_quantity: true,
    price: true,
    status: true,
    created_at: true
  }
};

export const orderShipmentListSelect = {
  id: true,
  order_id: true,
  supermarket_id: true,
  delivery_type_id: true,
  delivery_date: true,
  total: true,
  tax_total: true,
  status: true,
  driver_id: true,
  delivered_at: true,
  delivery_charges: true,
  time_slot_id: true,
  formatted_time_slot: true
};

export const deliveryBatchOrderShipmentSelect = {
  id: true,
  driver_id: true,
  delivery_date: true,
  delivery_type_id: true,
  order: {
    id: true,
    payment_option_id: true,
    delivery_option_id: true,
    order_addresses: {
      id: true,
      is_bill_address: true,
      district: true
    }
  },
  order_products: {
    id: true,
    product_id: true,
    quantity: true
  }
};

export const orderPickingShipmentsSelect = {
  ...orderShipmentSelect,
  order: {
    id: true,
    delivery_option_id: true,
    created_at: true
  },
  order_products: {
    id: true,
    quantity: true
  }
};

export const shipmentFulfillmentDetailsSelect = {
  id: true,
  dispatch_job_id: true,
  order_picking_shipment_bins: {
    id: true,
    bin_id: true,
    assigned_to: true,
    assigned_at: true,
    started_at: true,
    picked_at: true,
    dropped_at: true,
    staging_location_id: true,
    label_pdf_url: true
  },
  order_picking_shipment_products: {
    id: true,
    assigned_to: true,
    assigned_at: true,
    started_at: true,
    picked_at: true,
    dropped_at: true,
    staging_location_id: true,
    label_pdf_url: true,
    order_product_id: true,
    order_product: {
      picked_quantity: true,
      quantity: true,
      price: true,
      product_id: true,
      status: true,
      product_report: {
        id: true,
        location_id: true,
        comments: true,
        images: {
          id: true,
          image_url: true
        },
        product_report_reason: {
          id: true,
          name: true
        }
      }
    }
  },
  dispatch_job: {
    dispatch_location_id: true,
    completed_at: true,
    assigned_at: true,
    assigned_to: true,
    started_at: true,
    status: true
  },
  order_picking_shipment: {
    bins_count: true,
    products_count: true,
    estimated_time_completion: true,
    is_on_hold: true,
    order_picking_batch_id: true
  }
};
