export default {
  PRODUCT_IMAGES_REPOSITORY: 'PRODUCT_IMAGES_REPOSITORY',
  SIMILAR_PRODUCT_REPOSITORY: 'SIMILAR_PRODUCT_REPOSITORY',
  TAXONOMY_REPOSITORY: 'TAXONOMY_REPOSITORY',
  PRODUCT_REPOSITORY: 'PRODUCT_REPOSITORY',
  EMPLOYEE_REPOSITORY: 'EMPLOYEE_REPOSITORY',
  EMPLOYEE_SUPER_MARKET_REPOSITORY: 'EMPLOYEE_SUPER_MARKET_REPOSITORY',
  USER_REPOSITORY: 'USER_REPOSITORY',
  ROLE_REPOSITORY: 'ROLE_REPOSITORY',
  EMPLOYEE_OPERATIONAL_ROLE_REPOSITORY: 'EMPLOYEE_OPERATIONAL_ROLE_REPOSITORY',
  OPERATIONAL_EMPLOYEE_LOG_REPOSITORY: 'OPERATIONAL_EMPLOYEE_LOG_REPOSITORY',
  PERMISSION_REPOSITORY: 'PERMISSION_REPOSITORY',
  ROLE_PERMISSION_REPOSITORY: 'ROLE_PERMISSION_REPOSITORY',
  SUPER_MARKET_REPOSITORY: 'SUPER_MARKET_REPOSITORY',
  SUPER_MARKET_PAYMENT_REPOSITORY: 'SUPER_MARKET_PAYMENT_REPOSITORY',
  LANGUAGE_REPOSITORY: 'LANGUAGE_REPOSITORY',
  CITY_REPOSITORY: 'CITY_REPOSITORY',
  COUNTRY_REPOSITORY: 'COUNTRY_REPOSITORY',
  COUNTRY_TRANSLATION_REPOSITORY: 'COUNTRY_TRANSLATION_REPOSITORY',
  ADDRESS_REPOSITORY: 'ADDRESS_REPOSITORY',
  DISTRICT_REPOSITORY: 'DISTRICT_REPOSITORY',
  CONFIG_REPOSITORY: 'CONFIG_REPOSITORY',
  WISHLIST_REPOSITORY: 'WISHLIST_REPOSITORY',
  REDIS: 'REDIS',
  CUSTOMER_REPOSITORY: 'CUSTOMER_REPOSITORY',
  CURRENCY_REPOSITORY: 'CURRENCY_REPOSITORY',
  PAYMENT_OPTION_REPOSITORY: 'PAYMENT_OPTION_REPOSITORY',
  WAREHOUSE_REPOSITORY: 'WAREHOUSE_REPOSITORY',
  WAREHOUSE_PAYMENT_REPOSITORY: 'WAREHOUSE_PAYMENT_REPOSITORY',
  CATEGORY_REPOSITORY: 'CATEGORY_REPOSITORY',
  SUPER_MARKET_DISTRICT_REPOSITORY: 'SUPER_MARKET_DISTRICT_REPOSITORY',
  AISLE_SHELF_REPOSITORY: 'AISLE_SHELF_REPOSITORY',
  SUPPLIER_REPOSITORY: 'SUPPLIER_REPOSITORY',
  DELIVERY_OPTION_REPOSITORY: 'DELIVERY_OPTION_REPOSITORY',
  MANUFACTURER_REPOSITORY: 'MANUFACTURER_REPOSITORY',
  DELIVERY_TYPE_REPOSITORY: 'DELIVERY_TYPE_REPOSITORY',
  SUPERMARKET_DELIVERY_OPTION_REPOSITORY: 'SUPERMARKET_DELIVERY_OPTION_REPOSITORY',
  SUPERMARKET_DELIVERY_TYPE_REPOSITORY: 'SUPERMARKET_DELIVERY_TYPE_REPOSITORY',
  BIN_CATEGORY_REPOSITORY: 'BIN_CATEGORY_REPOSITORY',
  TAXATION_REPOSITORY: 'TAXATION_REPOSITORY',
  PRODUCT_DELIVERY_TYPE_REPOSITORY: 'PRODUCT_DELIVERY_TYPE_REPOSITORY',
  PRODUCT_DELIVERY_OPTION_REPOSITORY: 'PRODUCT_DELIVERY_OPTION_REPOSITORY',
  NUTRITIONAL_FACTS_REPOSITORY: 'NUTRITIONAL_FACTS_REPOSITORY',
  BRAND_REPOSITORY: 'BRAND_REPOSITORY',
  JOB_ENTITY_REPOSITORY: 'JOB_ENTITY_REPOSITORY',
  INVENTORY_MODIFIERS_REPOSITORY: 'INVENTORY_MODIFIERS_REPOSITORY',
  BUNDLE_PRODUCTS_REPOSITORY: 'BUNDLE_PRODUCTS_REPOSITORY',
  PRODUCT_BARCODE_REPOSITORY: 'PRODUCT_BARCODE_REPOSITORY',
  TAXON_REPOSITORY: 'TAXON_REPOSITORY',
  OPTION_TYPE_REPOSITORY: 'OPTION_TYPE_REPOSITORY',
  OPTION_VALUE_REPOSITORY: 'OPTION_VALUE_REPOSITORY',
  TAXON_COUNTRY_REPOSITORY: 'TAXON_COUNTRY_REPOSITORY',
  TAXON_GROUP_REPOSITORY: 'TAXON_GROUP_REPOSITORY',
  TAXON_GROUP_MEMBERSHIP_REPOSITORY: 'TAXON_GROUP_MEMBERSHIP_REPOSITORY',
  TAXON_PRODUCT_REPOSITORY: 'TAXON_PRODUCT_REPOSITORY',
  TIME_SLOT_REPOSITORY: 'TIME_SLOT_REPOSITORY',
  DELIVERY_CAPACITY_REPOSITORY: 'DELIVERY_CAPACITY_REPOSITORY',
  HOLIDAY_REPOSITORY: 'HOLIDAY_REPOSITORY',
  HOLIDAY_COUNTRY_REPOSITORY: 'HOLIDAY_COUNTRY_REPOSITORY',
  HOLIDAY_CITY_REPOSITORY: 'HOLIDAY_CITY_REPOSITORY',
  HOLIDAY_SUPERMARKET_REPOSITORY: 'HOLIDAY_SUPERMARKET_REPOSITORY',
  PRODUCT_VARIANT_REPOSITORY: 'PRODUCT_VARIANT_REPOSITORY',
  PRODUCT_VARIANT_IMAGE_REPOSITORY: 'PRODUCT_VARIANT_IMAGE_REPOSITORY',
  PRODUCT_VARIANT_BARCODE_REPOSITORY: 'PRODUCT_VARIANT_BARCODE_REPOSITORY',
  OFFLINE_PRODUCT_REPOSITORY: 'OFFLINE_PRODUCT_REPOSITORY',
  OFFLINE_PRODUCT_BARCODE_REPOSITORY: 'OFFLINE_PRODUCT_BARCODE_REPOSITORY',
  ORDER_CANCELLATION_REASON_REPOSITORY: 'ORDER_CANCELLATION_REASON_REPOSITORY',
  PROMOTION_REPOSITORY: 'PROMOTION_REPOSITORY',
  PROMOTION_RULES_REPOSITORY: 'PROMOTION_RULES_REPOSITORY',
  PROMOTION_ACTIONS_REPOSITORY: 'PROMOTION_ACTIONS_REPOSITORY',
  SUPERMARKET_DELIVERY_REGION_REPOSITORY: 'SUPERMARKET_DELIVERY_REGION_REPOSITORY',
  ORDER_REPOSITORY: 'ORDER_REPOSITORY',
  ORDER_SHIPMENT_REPOSITORY: 'ORDER_SHIPMENT_REPOSITORY',
  ORDER_PRODUCT_REPOSITORY: 'ORDER_PRODUCT_REPOSITORY',
  CUISINE_REPOSITORY: 'CUISINE_REPOSITORY',
  RECIPE_REPOSITORY: 'RECIPE_REPOSITORY',
  INGREDIENT_REPOSITORY: 'INGREDIENT_REPOSITORY',
  INGREDIENT_PRODUCT_REPOSITORY: 'INGREDIENT_PRODUCT_REPOSITORY',
  DEVICE_TOKEN_REPOSITORY: 'DEVICE_TOKEN_REPOSITORY',
  REFRESH_TOKEN_REPOSITORY: 'REFRESH_TOKEN_REPOSITORY',
  ORDER_REPLACEMENT_PRODUCT_REPOSITORY: 'ORDER_REPLACEMENT_PRODUCT_REPOSITORY',
  WMS_BIN_REPOSITORY: 'WMS_BIN_REPOSITORY',
  WMS_ZONE_REPOSITORY: 'WMS_ZONE_REPOSITORY',
  WMS_LOCATION_REPOSITORY: 'WMS_LOCATION_REPOSITORY',
  WMS_PURCHASE_ORDER_REPOSITORY: 'WMS_PURCHASE_ORDER_REPOSITORY',
  WMS_PURCHASE_ORDER_PRODUCT_REPOSITORY: 'WMS_PURCHASE_ORDER_PRODUCT_REPOSITORY',
  WMS_RECEIVING_REPOSITORY: 'WMS_RECEIVING_REPOSITORY',
  WMS_RECEIVING_PRODUCT_REPOSITORY: 'WMS_RECEIVING_PRODUCT_REPOSITORY',
  WMS_PRODUCT_REPORT_REPOSITORY: 'WMS_PRODUCT_REPORT_REPOSITORY',
  WMS_PRODUCT_REPORT_REASON_REPOSITORY: 'WMS_PRODUCT_REPORT_REASON_REPOSITORY',
  WMS_PRODUCT_REPORT_IMAGE_REPOSITORY: 'WMS_PRODUCT_REPORT_IMAGE_REPOSITORY',
  VEHICLE_MANAGEMENT_REPOSITORY: 'VEHICLE_MANAGEMENT_REPOSITORY',
  WMS_STOCK_MASTER_REPOSITORY: 'WMS_STOCK_MASTER_REPOSITORY',
  WMS_STOCK_LOCATION_REPOSITORY: 'WMS_STOCK_LOCATION_REPOSITORY',
  WMS_PUTAWAY_REPOSITORY: 'WMS_PUTAWAY_REPOSITORY',
  CHECKOUT_CARDS_REPOSITORY: 'CHECKOUT_CARDS_REPOSITORY',
  HOLD_REASON_REPOSITORY: 'HOLD_REASON_REPOSITORY',
  ORDER_PICKING_HOLD_REPOSITORY: 'ORDER_PICKING_HOLD_REPOSITORY',
  ORDER_PAYMENT_REPOSITORY: 'ORDER_PAYMENT_REPOSITORY',
  DELIVERY_ZONE_REPOSITORY: 'DELIVERY_ZONE_REPOSITORY',
  DELIVERY_ZONE_DISTRICT_REPOSITORY: 'DELIVERY_ZONE_DISTRICT_REPOSITORY',
  ORDER_PICKING_BATCH_REPOSITORY: 'ORDER_PICKING_BATCH_REPOSITORY',
  ORDER_PICKING_SHIPMENT_REPOSITORY: 'ORDER_PICKING_SHIPMENT_REPOSITORY',
  ORDER_PICKING_SHIPMENT_BINS_REPOSITORY: 'ORDER_PICKING_SHIPMENT_BINS_REPOSITORY',
  ORDER_PICKING_SHIPMENT_PRODUCTS_REPOSITORY: 'ORDER_PICKING_SHIPMENT_PRODUCTS_REPOSITORY',
  ORDER_PICKING_SHIPMENT_BIN_PRODUCTS_REPOSITORY: 'ORDER_PICKING_SHIPMENT_BIN_PRODUCTS_REPOSITORY',
  DISPATCH_JOB_REPOSITORY: 'DISPATCH_JOB_REPOSITORY',
  FEEDBACK_REPOSITORY: 'FEEDBACK_REPOSITORY',
  FEEDBACK_IMAGE_REPOSITORY: 'FEEDBACK_IMAGE_REPOSITORY',
  ORDER_PAYMENT_REFUND_REPOSITORY: 'ORDER_PAYMENT_REFUND_REPOSITORY',
  ORDER_ADDRESS_REPOSITORY: 'ORDER_ADDRESS_REPOSITORY',
  ORDER_SHIPMENT_REPORT_REASON_REPOSITORY: 'ORDER_SHIPMENT_REPORT_REASON_REPOSITORY',
  DISPATCH_JOB_HOLD_REPOSITORY: 'DISPATCH_JOB_HOLD_REPOSITORY',
  ORDER_SHIPMENT_REPORT_REPOSITORY: 'ORDER_SHIPMENT_REPORT_REPOSITORY',
  ORDER_ADJUSTMENT_REPOSITORY: 'ORDER_ADJUSTMENT_REPOSITORY',
  ORDER_ADJUSTMENT_REASON_REPOSITORY: 'ORDER_ADJUSTMENT_REASON_REPOSITORY',
  USER_GIFT_CARDS_REPOSITORY: 'USER_GIFT_CARDS_REPOSITORY',
  ORDER_PROMOTION_REPOSITORY: 'ORDER_PROMOTION_REPOSITORY',
  ORDER_SHIPMENT_RESCHEDULE_HISTORY_REPOSITORY: ' ORDER_SHIPMENT_RESCHEDULE_HISTORY_REPOSITORY',
  STORE_CREDIT_REPOSITORY: 'STORE_CREDIT_REPOSITORY',
  STORE_CREDIT_TYPE_REPOSITORY: 'STORE_CREDIT_TYPE_REPOSITORY',
  STORE_CREDIT_CATEGORY_REPOSITORY: 'STORE_CREDIT_CATEGORY_REPOSITORY',
  STORE_CREDIT_TRANSACTION_REPOSITORY: 'STORE_CREDIT_TRANSACTION_REPOSITORY',
  STORE_CREDIT_UPDATE_REASON_REPOSITORY: 'STORE_CREDIT_UPDATE_REASON_REPOSITORY',
  CASHBACK_REPOSITORY: 'CASHBACK_REPOSITORY',
  VEHICLE_HEALTH_EVIDENCE_REPOSITORY: ' VEHICLE_HEALTH_EVIDENCE_REPOSITORY',
  VEHICLE_HEALTH_EVIDENCE_IMAGE_REPOSITORY: ' VEHICLE_HEALTH_EVIDENCE_IMAGE_REPOSITORY',
  REFERRAL_REPOSITORY: 'REFERRAL_REPOSITORY',
  REFERRAL_PROGRAM_REPOSITORY: 'REFERRAL_PROGRAM_REPOSITORY',
  PRODUCT_PRICE_VERSION_REPOSITORY: 'PRODUCT_PRICE_VERSION_REPOSITORY',
  SHIPMENT_FULFILLMENT_HISTORY: 'SHIPMENT_FULFILLMENT_HISTORY',
  SHIPMENT_RETURNED_ITEM: 'SHIPMENT_RETURNED_ITEM',
  LOYALTY_REWARD_REPOSITORY: 'LOYALTY_REWARD_REPOSITORY',
  INVOICE_REPOSITORY: 'INVOICE_REPOSITORY',
  INVOICE_ZATCA_REPOSITORY: 'INVOICE_ZATCA_REPOSITORY',
  DISPATCH_JOB_RETURNS_REPOSITORY: 'DISPATCH_JOB_RETURNS_REPOSITORY',
  RETURN_REASON_REPOSITORY: 'RETURN_REASON_REPOSITORY',
  RETURN_REQUEST_REPOSITORY: 'RETURN_REQUEST_REPOSITORY',
  RETURN_REQUEST_PRODUCT_REPOSITORY: 'RETURN_REQUEST_PRODUCT_REPOSITORY'
};
