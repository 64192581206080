import { DeepPartial } from 'typeorm';
import { IBaseAudit } from '../auditable.interface';
import { ICommonOption } from '../common.interface';
import { IReturnReason } from '../fulfillment/dispatch-job-return.interface';
import { PaymentOptionCodeEnum } from '../payment-option.interface';
import { IProduct } from '../product/product.interface';
import { IUser } from '../user.interface';
import { IOrderShipment } from './order-shipment.interface';

export interface IReturnRequest extends IBaseAudit {
  order_shipment_id: number;
  created_by: number;
  creator_type: ReturnRequestCreatorTypeEnum;
  status: ReturnRequestStatusEnum;
  refund_amount: number;
  refund_mode?: number;
  approved_by?: number;
  approved_at?: Date;
  cancelled_by?: number;
  cancelled_at?: Date;
  cancellation_comments?: string;
  refunded_at?: Date;
  refund_status?: ReturnRequestRefundStatusEnum;
  assigned_to_pickup_at?: Date;
  pickup_batch_id?: number;
  return_request_products?: IReturnRequestProduct[];
  order_shipment?: IOrderShipment;
  supermarket_name?: string;
  customer?: IUser;
  approved_by_user?: IUser;
  created_by_user?: IUser;
  no_of_products?: number;
}

export interface IReturnRequestProduct extends IBaseAudit {
  return_request_id: number;
  order_product_id: number;
  bin_id?: number;
  product_id: number;
  product_variant_id?: number;
  quantity: number;
  refund_amount: number;
  return_reason_id: number;
  image_url?: string;
  comments?: string;
  fit_quantity?: number;
  returned_quantity?: number;
  product?: DeepPartial<IProduct>;
  return_reason?: IReturnReason;
  return_request?: IReturnRequest;
}

export interface IShipmentRetrunRequestItem {
  order_product_id: number;
  product_id?: number;
  product_name?: string;
  product_image?: string | null;
  refundable?: boolean;
  quantity: number;
  price: number;
}

export interface IDispatchJobShipmentReturnedItem {
  product_id: number;
  bin_id?: number;
  returned_quantity: number;
  image_url: string;
  return_reason_id: number;
  comments?: string;
}

export interface IShipmentRetrunRequestItemsList {
  items?: IShipmentRetrunRequestItem[];
  payment_options?: PaymentOptionCodeEnum[];
  currency?: string;
}

export interface IReturnRequestListData {
  page: number;
  id: number;
  supermarket_id: number;
  shipment_id: number;
  user_name: string;
  refund_amount: number;
  status: ReturnRequestStatusEnum;
  creator_type: ReturnRequestCreatorTypeEnum;
  refund_status: ReturnRequestRefundStatusEnum;
  approved_by: string;
  created_at: string;
}

export interface IReturnRequestListPayload extends IReturnRequestListData {
  [index: string]: string | number;
}

export interface ICreateReturnRequestPayload {
  refund_mode: number;
  items: ICreateReturnRequestItemPayload[];
  total_refund_amount: number;
}

export interface ICreateReturnRequestItemPayload {
  order_product_id: number;
  product_id: number;
  return_reason_id: number;
  quantity: number;
  comments?: string;
  image_url?: string;
}

export enum ReturnRequestGridFilterEnum {
  PAGE = 'page',
  ID = 'id',
  SUPERMARKET_ID = 'supermarket_id',
  SHIPMENT_ID = 'shipment_id',
  USER_NAME = 'user_name',
  REFUND_AMOUNT = 'refund_amount',
  STATUS = 'status',
  CREATOR_TYPE = 'creator_type',
  REFUND_STATUS = 'refund_status',
  APPROVED_BY = 'approved_by',
  CREATED_AT = 'created_at'
}

export enum ReturnRequestStatusEnum {
  PENDING = 'pending',
  APPROVED = 'approved',
  ASSIGNED_TO_PICKUP_BATCH = 'assigned-to-pickup-batch',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled'
}

export enum ReturnRequestCreatorTypeEnum {
  CUSTOMER = 'customer',
  ADMIN = 'admin',
  DRIVER = 'driver'
}

export enum ReturnRequestRefundStatusEnum {
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILED = 'failed'
}

export const returnRequestStatusOptions: ICommonOption<ReturnRequestStatusEnum>[] = [
  {
    id: ReturnRequestStatusEnum.PENDING,
    name: 'Pending'
  },
  {
    id: ReturnRequestStatusEnum.APPROVED,
    name: 'Approved'
  },
  {
    id: ReturnRequestStatusEnum.ASSIGNED_TO_PICKUP_BATCH,
    name: 'Assigned to Pickup Batch'
  },
  {
    id: ReturnRequestStatusEnum.COMPLETED,
    name: 'Completed'
  },
  {
    id: ReturnRequestStatusEnum.CANCELLED,
    name: 'Cancelled'
  }
];

export const returnRequestCreatorTypeOptions: ICommonOption<ReturnRequestCreatorTypeEnum>[] = [
  {
    id: ReturnRequestCreatorTypeEnum.CUSTOMER,
    name: 'Customer'
  },
  {
    id: ReturnRequestCreatorTypeEnum.ADMIN,
    name: 'Admin'
  },
  {
    id: ReturnRequestCreatorTypeEnum.DRIVER,
    name: 'Driver'
  }
];

export const returnRequestRefundStatusOptions: ICommonOption<ReturnRequestRefundStatusEnum>[] = [
  {
    id: ReturnRequestRefundStatusEnum.SUCCESS,
    name: 'Success'
  },
  {
    id: ReturnRequestRefundStatusEnum.PENDING,
    name: 'Pending'
  },
  {
    id: ReturnRequestRefundStatusEnum.FAILED,
    name: 'Failed'
  }
];

export const returnRequestsSelect = {
  id: true,
  refund_amount: true,
  status: true,
  creator_type: true,
  refunded_at: true,
  refund_status: true,
  approved_by: true,
  created_at: true,
  created_by: true,
  order_shipment: {
    id: true,
    supermarket_id: true,
    order: {
      currency: true,
      user_id: true
    }
  },
  return_request_products: { id: true }
};

export const returnRequestsConsumerListSelect = {
  id: true,
  status: true,
  refund_amount: true,
  refund_status: true,
  created_at: true,
  approved_at: true,
  refunded_at: true,
  assigned_to_pickup_at: true,
  order_shipment: {
    id: true,
    order: {
      id: true,
      currency: true,
      user_id: true
    }
  },
  return_request_products: {
    id: true,
    product_id: true,
    product_variant_id: true,
    refund_amount: true,
    quantity: true,
    return_reason: { name: true }
  }
};
