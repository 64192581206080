import { IBaseAudit } from './auditable.interface';
import { BaseSelect } from './common.interface';

export interface IDeliveryOption extends IBaseAudit {
  name: string;
}

export interface IGrpcGetDeliveryOptions {
  delivery_options: IDeliveryOption[];
}

export enum DeliveryOptionTypeEnum {
  HOME_DELIVERY = 1,
  STORE_PICKUP = 2
}

export const deliveryOptionSelect = {
  ...BaseSelect
};
