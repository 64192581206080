import { IBaseAudit } from './auditable.interface';

export interface IPaymentOption extends IBaseAudit {
  name?: string;
  code?: string;
}

export enum PaymentOptionCodeEnum {
  CREDIT = 'credit',
  APAY = 'a-pay',
  LOYPTS = 'loy-pts',
  COD = 'cod',
  EWLT = 'e-wlt'
}
