import { IBaseAudit } from '../auditable.interface';
import { ICommonOption } from '../common.interface';
import { IEmployeeData, OperationalEmployeeRoleEnum } from '../employee.interface';
import { IHoldReason } from '../hold-reason.interface';
import { IOrderShipment } from '../order/order-shipment.interface';
import { IPaymentOption } from '../payment-option.interface';
import { ISuperMarket } from '../supermarket/super-market.interface';
import { ITimeSlot } from '../supermarket/time-slot.interface';
import { IUser } from '../user.interface';
import { IVehicleManagement } from '../vehicle-management.interface';
import { IWmsLocation } from '../warehouse-management/wms-location.interface';
import { IDispatchJobReturn } from './dispatch-job-return.interface';

export interface IDispatchJob extends IBaseAudit {
  supermarket_id: number;
  distance?: number;
  duration?: number;
  path?: string;
  assigned_to: number;
  bins_quantity: number;
  delivery_date: Date;
  dispatch_location_id: number;
  assigned_at?: Date;
  status?: DispatchJobStatusEnum;
  delivery_type_id?: number;
  time_slot_id?: number;
  delivered_count?: number;
  assigned_count?: number;
  state?: string;
  started_at?: Date;
  completed_at?: Date;
  supervisor_comments?: string;
  dispatch_job_shipments?: IOrderShipment[];
  supermarket?: ISuperMarket;
  time_slot?: ITimeSlot;
  driver?: IEmployeeData;
  vehicle?: IVehicleManagement;
  dispatch_location?: IWmsLocation;
}

export interface IDispatchJobAnalytics {
  created_count: number;
  assigned_count: number;
  delivered_count: number;
  pending_count: number;
}

export interface IGrpcListDispatchJobReq {
  supermarket_ids: number[];
  time_slot_ids: number[];
  employee_ids: number[];
}

export interface ICreateDeliveryBatchPayload {
  supermarket_id: number;
  assigned_to: number;
  bins_quantity: number;
  delivery_type_id: number;
  time_slot_id: number;
  delivery_date: string;
  shipment_ids: number[];
}

export interface IGrpcListDispatchJobRes {
  supermarkets: ISuperMarket[];
  time_slots: ITimeSlot[];
  employees: IEmployeeData[];
}

export interface IGrpcValidateOperationalEmployeeReq {
  employee_id: number;
  supermarket_id: number;
  assigned_to: number;
  role: OperationalEmployeeRoleEnum;
}

export interface IGrpcValidateOperationalEmployeeRes {
  is_valid: boolean;
  vehicle_id?: number;
}

export const listDeliveryBatchSelect = {
  id: true,
  supermarket_id: true,
  assigned_to: true,
  bins_quantity: true,
  status: true,
  dispatch_location_id: true,
  delivery_type_id: true,
  time_slot_id: true,
  delivery_date: true,
  assigned_at: true,
  dispatch_job_shipments: {
    id: true,
    dispatch_job_id: true,
    delivered_at: true
  }
};

export interface IDeliveryBatchListData {
  page: number;
  id: string;
  supermarket_id: number;
  delivery_date: string;
  assigned_to: number;
  status: string;
}

export interface IDeliveryBatchListPayload extends IDeliveryBatchListData {
  [index: string]: string | number;
}

export interface IDeliveryBatchReassignPayload {
  assigned_to: number;
}

export interface IDeliveryBatchCompletePayload {
  supervisor_comments: string;
}

export interface IDispatchJobSupermarket {
  id: number;
  latitude: number;
  longitude: number;
  time_slots_lead_time: number;
}

export interface IListAssignedDispatchJobs {
  id: number;
  status: string;
  bins_count: number;
  oversized_products_count: number;
  total_orders_count: number;
  state: string;
  delivery_date: Date;
  supermarket: IDispatchJobSupermarket;
  time_slot?: ITimeSlot;
}

export interface IDispatchJobShipmentPaymentDue {
  payment_due: number;
}

export interface IDispatchJobHold {
  dispatch_job_shipment_id: number;
  reason_id: number;
  notes?: string;
  created_at?: Date;
  dispatch_job?: IDispatchJob;
  dispatch_job_shipment?: IOrderShipment;
  hold_reason?: IHoldReason;
}

export interface IGrpcOrderDetailsInDispatchJobItemReq {
  user_ids: number[];
  payment_option_ids: number[];
}

export interface IGrpcOrderDetailsInDispatchJobItemRes {
  users: IUser[];
  payment_options: IPaymentOption[];
}

export interface IUpdateDeliveryBatchShipment {
  id: number;
  delivery_order?: number;
  is_deleted?: boolean;
}

export interface IUpdateDeliveryBatchPayload {
  assigned_to: number;
  shipments: IUpdateDeliveryBatchShipment[];
}

interface ILatitudeLongitude {
  latitude: number;
  longitude: number;
}

export interface IComputeDeliveryRoutePoints {
  location: {
    latLng: ILatitudeLongitude;
  };
}

export interface IComputeDeliveryRoutePayload {
  origin: IComputeDeliveryRoutePoints;
  intermediates: IComputeDeliveryRoutePoints[];
  destination: IComputeDeliveryRoutePoints;
  travelMode: string;
  routingPreference: string;
  departureTime: string;
  computeAlternativeRoutes: boolean;
  routeModifiers: {
    avoidTolls: boolean;
    avoidHighways: boolean;
    avoidFerries: boolean;
  };
  languageCode: string;
  units: string;
  optimizeWaypointOrder: boolean;
  polylineEncoding: string;
  polylineQuality: string;
}

export interface IGoogleMapsMarkerLabel {
  color: string;
  fontWeight: string;
  fontSize: string;
}

interface IGoogleMapsPolyline {
  encodedPolyline: string;
}

export interface IGoogleMapsRoute {
  duration: string;
  distanceMeters: number;
  polyline: IGoogleMapsPolyline;
  optimizedIntermediateWaypointIndex?: number[];
}

export interface IGoogleMapsRoutesResponse {
  routes: IGoogleMapsRoute[];
}

export interface IComputedDeliveryRoute {
  path: string;
  distance: number;
  duration: number;
}

export interface IUnreturnedBin {
  order_shipment_id: number;
  bin_id: number;
}

export interface IUnreturnedOversizedProduct {
  order_shipment_id: number;
  product_id: number;
}

export interface IFinishDispatchJobValidation {
  unreturnedBins: IUnreturnedBin[];
  unreturnedProducts: IUnreturnedOversizedProduct[];
  dispatchJobReturns: Partial<IDispatchJobReturn>[];
}

export interface IDispatchJobOrderLanguage {
  order_language_id: number;
  user_id: number;
  order_id: number;
}

export interface IResettedShipments {
  resetted_shipments: number[];
}

export enum DispatchJobStatusEnum {
  PENDING = 'pending',
  IN_PROGRESS = 'in-progress',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled',
  ISSUE = 'issue'
}

export enum DeliveryBatchGridFilterEnum {
  PAGE = 'page',
  ID = 'id',
  SUPERMARKET_ID = 'supermarket_id',
  DELIVERY_DATE = 'delivery_date',
  STATUS = 'status',
  ASSIGNED_TO = 'assigned_to'
}

export const dispatchJobStatusOptions: ICommonOption<DispatchJobStatusEnum>[] = [
  {
    id: DispatchJobStatusEnum.PENDING,
    name: 'Pending'
  },
  {
    id: DispatchJobStatusEnum.IN_PROGRESS,
    name: 'In Progress'
  },
  {
    id: DispatchJobStatusEnum.COMPLETED,
    name: 'Completed'
  },
  {
    id: DispatchJobStatusEnum.CANCELLED,
    name: 'Cancelled'
  },
  {
    id: DispatchJobStatusEnum.ISSUE,
    name: 'Issue'
  }
];

export const assignedDispatchJobsSelect = {
  id: true,
  status: true,
  created_at: true,
  supermarket_id: true,
  state: true,
  delivery_date: true,
  time_slot_id: true,
  dispatch_job_shipments: {
    id: true,
    order_picking_shipment_bins: true,
    order_picking_shipment_products: true
  }
};

export const dispatchJobDetailsSelect = {
  id: true,
  distance: true,
  duration: true,
  path: true,
  assigned_to: true,
  bins_quantity: true,
  supermarket_id: true,
  status: true,
  dispatch_location_id: true,
  delivery_type_id: true,
  time_slot_id: true,
  delivery_date: true,
  dispatch_job_shipments: {
    id: true,
    delivery_order: true,
    delivery_date: true,
    delivery_type_id: true,
    order_products: {
      product_id: true,
      quantity: true
    },
    order: {
      id: true,
      payment_option_id: true
    }
  }
};

export const dispatchJobOrderShipmentsSelect = {
  id: true,
  status: true,
  supermarket_id: true,
  order_products: {
    id: true,
    category_id: true,
    product_id: true,
    quantity: true,
    price: true,
    picked_quantity: true,
    picked_weight: true
  }
};
