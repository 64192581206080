import JSZip from 'jszip';

import responseConstant from '../../constants/response.constant';
import { IFileValidationResponse } from '../../interface/file-validation-response.interface';

export async function zipFileValidator(zipFile: File, acceptableFileTypes: string[]): Promise<IFileValidationResponse> {
  const zip: JSZip = new JSZip();
  let result: IFileValidationResponse = {
    valid: false,
    error: ''
  };

  await zip.loadAsync(zipFile).then((zip: JSZip): void => {
    result = validateZipFile(zip, acceptableFileTypes);
  });

  return result;
}

export function validateZipFile(zip: JSZip, acceptableFileList: string[]): IFileValidationResponse {
  const result: IFileValidationResponse = {
    valid: false,
    error: ''
  };
  zip = removeDir(zip);

  if (Object.keys(zip.files).length > 0) {
    const allFiles: JSZip.JSZipObject[] = zip.filter((_relativePath: string, file: JSZip.JSZipObject): boolean => {
      return file?.name?.indexOf('__MACOSX/') === -1 && file?.name?.indexOf('.DS_Store') === -1;
    });
    const csvFiles: JSZip.JSZipObject[] = allFiles.filter((file: JSZip.JSZipObject): boolean => {
      return file?.name?.toLowerCase().endsWith('.csv');
    });
    const unAcceptableFiles: JSZip.JSZipObject[] = allFiles.filter((file: JSZip.JSZipObject): boolean => {
      return !acceptableFileList.includes(file?.name?.substring(file?.name?.lastIndexOf('.') + 1));
    });

    if (csvFiles.length < 1 || unAcceptableFiles.length > 0) result.error = responseConstant.INVALID_ZIP_FILE;
    else if (csvFiles.length > 1) result.error = responseConstant.MULTI_CSV_UPLOAD_ERROR;
    else result.valid = true;
  } else {
    result.error = responseConstant.INVALID_ZIP_FILE;
  }

  return result;
}

function removeDir(zip: JSZip): JSZip {
  const dirList = zip.filter((_relativePath: string, file: JSZip.JSZipObject): boolean => file.dir);
  dirList?.forEach((dir: JSZip.JSZipObject): boolean => delete zip.files[dir.name]);

  return zip;
}
