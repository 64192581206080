import moment, { Moment } from 'moment';

import { constants } from '../constants';
import { bulkUploadDateFormat, IExtraLabel, ReasonType } from '../interface/bulk-upload.interface';

export const getFileNameFromAzureUrl = (azureUrl: string): string => {
  const url: string | undefined = azureUrl.split('?').shift();
  return url?.split('/').pop() ?? '';
};

export const invalidProductReason = (reasonType: ReasonType, label: string, extraLabel?: IExtraLabel): string => {
  const reasonLabel = '{label}';
  switch (reasonType) {
    case ReasonType.EMPTY:
      return constants.EMPTY_REASON.replace(reasonLabel, label);

    case ReasonType.NOT_EXIST:
      return constants.NOT_EXIST_REASON.replace(reasonLabel, label);

    case ReasonType.NOSPACE:
      return constants.NO_SPACE_REASON.replace(reasonLabel, label);

    case ReasonType.ALPHA_ONLY:
      return constants.ALPHA_REASON.replace(reasonLabel, label);

    case ReasonType.NUMERIC_ONLY:
      return constants.NUMERIC_REASON.replace(reasonLabel, label);

    case ReasonType.ALPHANUMERIC_ONLY:
      return constants.ALPHA_NUMERIC_REASON.replace(reasonLabel, label);

    case ReasonType.ALPHANUMERIC_WITH_SPACE:
      return constants.ALPHA_NUMERIC_SPACE_REASON.replace(reasonLabel, label);

    case ReasonType.ARABIC_ALPHANUMERIC_WITH_SPACE:
      return constants.ARABIC_ALPHANUM_SPACE_ERROR.replace(reasonLabel, label);

    case ReasonType.ALPHANUMERIC_WITH_SPECIAL_CHAR_SPACE:
      return constants.ALPHA_NUMERIC_SPECIAL_CHAR_SPACE_REASON.replace(reasonLabel, label);

    case ReasonType.ARABIC_ALPHANUMERIC_WITH_SPECIAL_CHAR_SPACE:
      return constants.ARABIC_ALPHANUM_SPECIAL_CHAR_SPACE_ERROR.replace(reasonLabel, label);

    case ReasonType.ARABIC_ENGLISH_ALPHANUMERIC_WITH_SPECIAL_CHAR_SPACE:
      return constants.ARABIC_ENGLISH_ALPHANUM_SPECIAL_CHAR_SPACE_ERROR.replace(reasonLabel, label);

    case ReasonType.FILE_NOT_FOUND:
      return constants.FILE_NOT_FOUND_REASON.replace(reasonLabel, label);

    case ReasonType.DUPLICATE_LABEL:
      return constants.DUPLICATE_REASON.replace(reasonLabel, label);

    case ReasonType.BUNDLE_OF_BUNDLE:
      return constants.BUNDLE_OF_BUNDLE_REASON.replace(reasonLabel, label);

    case ReasonType.ALREADY_EXIST_LABEL:
      return constants.ALREADY_EXIST_REASON.replace(reasonLabel, label);

    case ReasonType.DATE_GREATER_THAN: {
      let reason: string = constants.DATE_GREATER_THAN_REASON.replace(reasonLabel, label);
      reason = reason.replace('{start_date}', extraLabel?.start_date ?? extraLabel?.date ?? '0');
      reason = extraLabel?.supermarket_id ? reason + ` for {${extraLabel.supermarket_id}}` : reason;
      return reason;
    }

    case ReasonType.DATE_LESS_THAN: {
      let reason: string = constants.DATE_LESS_THAN_REASON.replace(reasonLabel, label);
      reason = reason.replace('{date}', extraLabel?.date ?? '0');
      return reason;
    }

    case ReasonType.DATE_FORMAT: {
      let reason: string = (extraLabel?.is_time ? constants.TIME_FORMAT_REASON : constants.DATE_FORMAT_REASON).replace(
        reasonLabel,
        label
      );
      reason = reason.replace('{date_format}', extraLabel?.date_time_format ?? bulkUploadDateFormat);
      return reason;
    }

    case ReasonType.CONTAINS: {
      let reason: string = constants.CONTAINS_REASON.replace(reasonLabel, label);
      reason = reason.replace('{contains}', extraLabel?.contains ?? '0');
      return reason;
    }

    case ReasonType.DECIMAL_PLACES: {
      let reason: string = constants.DECIMAL_PLACES_REASON.replace(reasonLabel, label);
      reason = reason.replace('{decimal_places}', extraLabel?.decimal_places ?? '0');
      return reason;
    }

    case ReasonType.CHARACTERS_LENGTH: {
      let reason: string = constants.CHARACTERS_LENGTH_REASON.replace(reasonLabel, label);
      reason = reason.replace('{length}', String(extraLabel?.length) || '1');
      return reason;
    }

    case ReasonType.RANGE: {
      let reason: string = constants.NUMBER_RANGE_REASON.replace(reasonLabel, label);
      reason = reason.replace('{range_start}', String(extraLabel?.range_start) || '0');
      reason = reason.replace('{range_end}', String(extraLabel?.range_end) || '0');
      return reason;
    }

    case ReasonType.MAX_COUNT: {
      let reason: string = constants.MAX_COUNT_REASON.replace(reasonLabel, label);
      reason = reason.replace('{length}', String(extraLabel?.length) || '1');
      return reason;
    }

    case ReasonType.ALREADY_MAPPED: {
      let reason: string = constants.ALREADY_MAPPED_REASON.replace(reasonLabel, label);
      reason = reason.replace('{mapped_to}', extraLabel?.mapped_to || 'product');
      return reason;
    }

    default:
      return '';
  }
};

export const stringToDate = (dateString: string, dateFormat: string): Date | null => {
  const date: Moment = moment(dateString, dateFormat);

  return dateString && date.isValid() ? date.utc().toDate() : null;
};

export const formatDate = (date: Date, dateFormat: string, currentDateFormat?: string): string | null => {
  const momentDate: Moment = moment(date, currentDateFormat);

  return date && momentDate.isValid() ? momentDate.format(dateFormat) : null;
};

export const localToUtcDateFormat = (date: Date): Date => {
  return moment(date).set('s', 0).set('ms', 0).toDate();
};

export function fetchFirstLetterInWord(value: string, separator = ' '): string {
  const words: string[] = value.split(separator);
  const initials: string[] = words.map((word: string): string => word.charAt(0).toUpperCase());

  return initials.join('');
}

export function percentageCalculator(total: number, percentage: number): number {
  return (total ?? 0) * ((percentage ?? 0) / 100);
}

export function setFallbackValue<T>(value: T, fallback: T): T {
  return value ?? fallback;
}

export function setFallbackStringInteger(value: string, fallback: number | null): number | null {
  return value ? Number(value) : fallback;
}

export function downloadBlob(fileName: string, blob: Blob): void {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = fileName;
  link.click();
  window.URL.revokeObjectURL(link.href);
}

export function generateMathRandom(): number {
  const crypto: Crypto = window.crypto;
  const typedArray: Uint8Array = new Uint8Array(1);
  const randomValue: number = crypto.getRandomValues(typedArray)[0];
  const randomFloat: number = randomValue / Math.pow(2, 8);
  return randomFloat;
}
