import { IBaseAudit } from '../auditable.interface';
import { BaseSelect, ICommonName, ICommonOption, LocaleEnum } from '../common.interface';
import { IEmployeeData } from '../employee.interface';
import { IOrderShipment, OrderShipmentStatusEnum } from '../order/order-shipment.interface';
import { IHoldReason } from '../hold-reason.interface';
import { IProduct } from '../product/product.interface';
import { ISuperMarket, SupermarketTypeEnum } from '../supermarket/super-market.interface';
import { IProductVariant, IProductVariantBarcode, IProductVariantImage } from '../product/product-variant.interface';
import { OrderProductReplacementPrefEnum, OrderProductStatusEnum } from '../order/order-product.interface';
import { IWmsStockLocation } from '../warehouse-management/wms-stock-management.interface';
import { IUser } from '../user.interface';
import { IWmsBin } from '../warehouse-management/wms-bin.interface';
import { IOfflineProduct } from '../product/offline-products.interface';
import { BinCategoryEnum, BinCategorySelect } from '../product/bin-category.interface';
import { IWmsLocation } from '../warehouse-management/wms-location.interface';
import { IOrderPickingShipmentBins, IOrderPickingShipmentProducts } from '../order/order-picking-stagger.interface';
import { IPaymentOption } from '../payment-option.interface';
import { IOrderAddress } from '../order/order-address.interface';

export interface IOrderPickingBatch extends IBaseAudit {
  supermarket_id: number;
  products_count: number;
  picks_count: number;
  bins_count: number;
  assigned_to: number;
  assigned_at: Date;
  status: OrderPickingBatchStatusEnum;
  estimated_time_completion: number;
  state?: string;
  time_spent?: number;
  started_at?: Date;
  completed_at?: Date;
  picker?: IEmployeeData;
  supermarket_type?: SupermarketTypeEnum;
  order_picking_shipments?: IOrderPickingShipment[];
  hold_reasons?: IOrderPickingHold[];
}

export interface IOrderPickingShipment extends IBaseAudit {
  order_shipment_id: number;
  order_picking_batch_id: number;
  products_count: number;
  picks_count: number;
  bins_count: number;
  estimated_time_completion: number;
  is_on_hold: boolean;
  order_shipment?: IOrderShipment;
  order_picking_batch?: IOrderPickingBatch;
  hold_reasons?: IOrderPickingHold;
}

export interface IOrderPickingHold {
  order_picking_shipment_id: number;
  reason_id: number;
  notes?: string;
  created_at?: Date;
  order_picking_batch?: IOrderPickingBatch;
  hold_reason?: IHoldReason;
}

export interface IOrderPickingBatchListData {
  page: number;
  batch_id: number;
  assigned_to: number;
  status: string;
}

export interface IOrderPickingProductLocation {
  location_id: number;
  product_id: number;
  product_variant_id: number;
  picked_quantity: number;
}

export interface IOrderPickingBatchListPayload extends IOrderPickingBatchListData {
  [index: string]: string | number;
}

export interface IOrderPickingProduct extends IProduct {
  currency: string;
  weighted: boolean;
  allow_to_call_customer: boolean;
  call_mask: boolean;
  order_id: number;
  order_shipment_id: number;
  order_product_id: number;
  quantity: number;
  price: number;
  action_price: number;
  customer_country_code?: string;
  customer_mobile_number?: string;
  delivery_instruction?: string;
  product_variants?: IOrderPickingProductVariant[];
  replacement_pref?: OrderProductReplacementPrefEnum;
  replacement_products?: IOrderPickingProduct[];
  stock_locations?: IWmsStockLocation[];
  status?: OrderProductStatusEnum;
}

export interface IOrderPickingProductsAndBins {
  products: IOrderPickingProduct[];
  bins: IWmsBin[];
}

export interface IOrderPickingReplacedProduct {
  shipment_id: number;
  product_id: number;
  product_variant_id: number;
  picked_quantity?: number;
  picked_weight?: number;
  location_id: number;
  reported: boolean;
  replacement_product_id?: number;
  replacement_product_variant_id?: number;
  replacement_picked_quantity?: number;
  replacement_picked_weight?: number;
  replacement_location_id?: number;
}

export interface IGrpcListOrderPickingBatchReq {
  employee_ids: number[];
}

export interface IGrpcListOrderPickingBatchRes {
  employees: IEmployeeData[];
}

export interface IGrpcOrderPickingGetSupermarketReq {
  assigned_to: number;
  supermarket_ids: number[];
  employee_id?: number;
}

export interface IGrpcOrderPickingGetSupermarketRes {
  is_valid: boolean;
  supermarket: ISuperMarket;
}

export interface IGrpcCreateOrderPickingReq {
  productIds: number[];
  variantIds?: number[];
  offlineProductIds?: number[];
}

export interface IGrpcCreateOrderPickingRes {
  products: IProduct[];
  variants?: IProductVariant[];
  offline_products?: IOfflineProduct[];
}

export interface IUserGrpcListOrderPickingProductsReq {
  supermarket_id: number;
  customer_ids?: number[];
}

export interface IUserGrpcListOrderPickingProductsRes {
  allow_to_call_customer: boolean;
  call_mask: boolean;
  customers: IUser[];
}

export interface IGrpcListOrderPickingProductsReq {
  locale: LocaleEnum;
  supermarket_id: number;
  product_ids: number[];
  variant_ids: number[];
}

export interface IGrpcListOrderPickingProductsRes {
  products: IProduct[];
  variants: IOrderPickingProductVariant[];
}

export interface IOrderPickingProductVariant {
  id: number;
  option_type: ICommonName;
  option_value: ICommonName;
  images?: IProductVariantImage[];
  barcodes?: IProductVariantBarcode[];
}

export interface IOrderPickingOnHoldShipment {
  shipment_id: number;
  is_on_hold: boolean;
}

export interface IGrpcListAdminOrderPickingProductsReq {
  product_ids: number[];
}

export interface IGrpcListAdminOrderPickingProductsRes {
  products: IProduct[];
}

export interface IGrpcListOrderPickingJobProductsReq {
  product_ids: number[];
  variant_ids: number[];
  locale?: LocaleEnum;
}

export interface IListOrderPickingJobProductVariant {
  id: number;
  option_type: ICommonName;
  option_value: ICommonName;
  images?: IProductVariantImage[];
  barcodes?: IProductVariantBarcode[];
}

export interface IGrpcListOrderPickingJobProductsRes {
  products: IProduct[];
  variants: IListOrderPickingJobProductVariant[];
}

export interface IOrderPickingJobBin {
  order_picking_shipment_bin_id: number;
  bin_id: number;
  staging_location: IWmsLocation;
  dispatch_location: IWmsLocation;
  currency: string;
  products: IOrderPickingJobBinProducts[];
  picked_at?: Date;
  dropped_at?: Date;
  isLabelPrinted?: boolean;
}

export interface IOrderPickingJobBinProducts {
  order_picking_shipment_bin_product_id: number;
  quantity: number;
  product: IListOrderPickingJobProduct;
  price: number;
  action_price: number;
  staging_location?: IWmsLocation;
  bin_category?: BinCategoryEnum;
}

export interface IOrderPickingJobProduct {
  order_picking_shipment_product_id: number;
  staging_location: IWmsLocation;
  dispatch_location: IWmsLocation;
  product: IListOrderPickingJobProduct;
  quantity: number;
  price: number;
  action_price: number;
  currency: string;
  picked_at?: Date;
  dropped_at?: Date;
  bin_category?: BinCategoryEnum;
  isLabelPrinted?: boolean;
}

export type IListOrderPickingJobItems = {
  order_id: number;
  order_shipment_id: number;
  is_store_pickup?: boolean;
  bins: IOrderPickingJobBin[];
  products: IOrderPickingJobProduct[];
  status?: OrderShipmentStatusEnum;
  delivery_date?: Date;
  delivered_at?: Date;
  is_delivery_on_hold?: boolean;
  is_resetted?: boolean;
  shipment_total?: number;
  payment_due?: number;
  currency?: string;
  currency_precision?: number;
  user?: IUser;
  payment_option?: IPaymentOption;
  delivery_address?: IOrderAddress;
  call_mask?: boolean;
};

export interface IListOrderPickingJobProduct extends IProduct {
  variant?: IListOrderPickingJobProductVariant;
}

export interface IGetDispatchJobProductsDetail extends IGrpcListOrderPickingJobProductsRes {
  orderPickingShipmentBins: IOrderPickingShipmentBins[];
  orderPickingShipmentProducts: IOrderPickingShipmentProducts[];
}

export interface IUserGrpcCompleteOrderPickingBatchReq {
  supermarket_ids: number[];
}

export interface IUserGrpcCompleteOrderPickingBatchRes {
  supermarkets: ISuperMarket[];
}

export interface IDispatchJobOrderPickingBinBarcode {
  id: number;
  barcode_label: string;
}

export interface IDispatchJobOrderPickingProduct {
  product: IListOrderPickingJobProduct;
  bin_category: string;
}

export interface IDispatchJobOrderPickingItems {
  order_id: number;
  order_shipment_id: number;
  bins: IDispatchJobOrderPickingBinBarcode[];
  products: IDispatchJobOrderPickingProduct[];
}

export interface IOnlinePickersForOrderPicking {
  supermarket_id: number;
  picker_id?: number;
  picks_count: number;
  batch_assigned: boolean;
  order_picking_batches: IOrderPickingBatch[];
  language_id: number;
}

export enum OrderPickingAssignmentGridFilterEnum {
  PAGE = 'page',
  BATCH_ID = 'batch_id',
  STATUS = 'status',
  ASSIGNED_TO = 'assigned_to'
}

export enum OrderPickingBatchStatusEnum {
  PENDING = 'pending',
  IN_PROGRESS = 'in-progress',
  COMPLETED = 'completed',
  CANCELLED = 'cancelled'
}

export const orderPickingBatchStatusOptions: ICommonOption<OrderPickingBatchStatusEnum>[] = [
  {
    id: OrderPickingBatchStatusEnum.PENDING,
    name: 'Pending'
  },
  {
    id: OrderPickingBatchStatusEnum.IN_PROGRESS,
    name: 'In Progress'
  },
  {
    id: OrderPickingBatchStatusEnum.COMPLETED,
    name: 'Completed'
  },
  {
    id: OrderPickingBatchStatusEnum.CANCELLED,
    name: 'Cancelled'
  }
];

export const orderPickingBatchListSelect = {
  id: true,
  products_count: true,
  picks_count: true,
  bins_count: true,
  assigned_to: true,
  assigned_at: true,
  status: true,
  estimated_time_completion: true
};

export const orderPickingBatchDetailSelect = {
  id: true,
  supermarket_id: true,
  status: true,
  products_count: true,
  picks_count: true,
  bins_count: true,
  estimated_time_completion: true,
  time_spent: true,
  state: true,
  order_picking_shipments: {
    id: true,
    products_count: true,
    picks_count: true,
    bins_count: true,
    estimated_time_completion: true,
    is_on_hold: true,
    order_shipment: {
      order_id: true,
      delivery_type_id: true,
      delivery_date: true,
      time_slot_id: true,
      formatted_time_slot: true,
      order: {
        delivery_option_id: true,
        created_at: true
      }
    }
  }
};

export const orderPickingProductSelect = {
  ...BaseSelect,
  name_ar: true,
  sku: true,
  weight: true,
  default_weight_count: true,
  weight_increment: true,
  max_weight_per_order: true,
  images: {
    id: true,
    file_name: true
  },
  bin_category: { ...BinCategorySelect },
  barcodes: { barcode: true },
  inventory_modifiers: {
    supermarket_id: true,
    action_price: true,
    sale_price: true,
    track_stock: true
  },
  bundled: true,
  child_products: {
    child_product_id: true,
    quantity: true,
    child_product: {
      id: true,
      name: true,
      sku: true,
      weight: true,
      default_weight_count: true,
      weight_increment: true,
      barcodes: {
        id: true,
        barcode: true
      }
    }
  }
};

export const orderPickingProductVariantSelect = {
  id: true,
  option_type_id: true,
  option_type: {
    name_en: true,
    name_ar: true
  },
  option_value_id: true,
  images: { url: true },
  option_value: { name: true },
  barcodes: { barcode: true }
};

export const orderPickingProductStockLocationSelect = {
  product_id: true,
  product_variant_id: true,
  zone: {
    id: true,
    name: true
  },
  location: {
    id: true,
    aisle: true,
    position: true,
    bay: true,
    level: true,
    division: true
  },
  units: true
};

export const orderPickingProductAdminSelect = {
  ...BaseSelect,
  sku: true,
  weight: true,
  default_weight_count: true,
  weight_increment: true,
  max_weight_per_order: true,
  images: {
    id: true,
    file_name: true
  },
  barcodes: { barcode: true }
};

export const activeOrderPickingBatchesSelect = {
  id: true,
  assigned_to: true,
  status: true,
  order_picking_shipments: {
    id: true,
    order_shipment: {
      id: true,
      status: true,
      order_products: {
        id: true,
        status: true
      }
    }
  }
};

export const orderPickingBatchSelect = {
  started_at: true,
  completed_at: true,
  assigned_at: true,
  assigned_to: true,
  status: true
};
