<div class="flex flex-column align-items-center bg-white">
  <img
    *ngIf="barcode"
    id="barcodeImage{{ barcode }}"
    class="flex"
    alt="barcode"
    [style.width]="width"
    [style.height]="height"
  />
  <p *ngIf="showLabel" class="barcode-text line-height-1" [ngStyle]="{ 'font-size': fontSize }">
    {{ label || barcode }}
  </p>
</div>
