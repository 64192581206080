import { IAuditable, IBaseAuditWithoutId } from './auditable.interface';
import { UserTypeEnum } from './user.interface';

export interface IDeviceToken extends IBaseAuditWithoutId {
  user_id: number;
  user_type: UserTypeEnum;
  device_id: string;
  device_type?: string;
  token?: string;
}

export interface IRefreshToken extends IAuditable {
  user_id: number;
  token: string;
  browser?: string;
  os?: string;
  device?: string;
  expiry?: string;
  expired_at?: Date;
}

export interface IGrpcRemoveInvalidDeviceTokenReq {
  user_id: number;
  device_id: string;
  user_type: UserTypeEnum;
}
